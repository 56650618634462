import React, { Component } from 'react'
import Layout from '../components/layout'
import IframeResizer from 'iframe-resizer-react'

export default class Bewerber extends Component {
    render() {
        return (
            <Layout>
                <section id="intro">
                    <header className="section-header">
                        <h3>Herzlich Willkommen</h3>
                    </header>
                    <p><strong>Sie haben uns gerade noch gefehlt ...</strong></p>
                    <div style={{ width: '80%', margin: '0 auto' }}>
                        <IframeResizer
                            log
                            src="https://leparec.abacuscity.ch/de/jobportal"
                            style={{ width: '1px', minWidth: '100%', border: '0' }}
                        />
                    </div>
                </section>
            </Layout >
        )
    }
}
